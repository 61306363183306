//
// Flipclock Base Styles
//

$fc-font-family:    "Helvetica Neue", Helvetica, sans-serif !default;

$fc-face-color:       #ccc !default;
$fc-face-background:  #333 !default;

.flipclock-wrapper {
  background: red;
  font-family: $fc-font-family;
  font-size: 16px;
  -webkit-user-select: none;

  text-align: center;
  position: relative;
  width: 100%;
  margin: 1em;

  * {
    box-sizing: border-box;
    backface-visibility: hidden;
  }

  ul {
    position: relative;
    float: left;
    margin: 5px;
    width: 60px;
    height: 90px;
    font-size: 80px;
    font-weight: bold;
    line-height: 87px;
    border-radius: 6px;
    background: #000;
  }

  .flipclock-label {
    font-size: .75em;
  }

  // clearfix IE8 and up
  &.clearfix,
  .clearfix {
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }

}




.flipclock-wrapper ul {
    list-style: none;
}
/* Main */

.flipclock-meridium {
    background: none !important;
    box-shadow: 0 0 0 !important;
    font-size: 36px !important;
}
.flipclock-meridium a {
    color: #313333;
}

/* Skeleton */

.flipclock-wrapper ul {

}
.flipclock-wrapper ul li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    line-height: 87px;
    text-decoration: none !important;
}
.flipclock-wrapper ul li:first-child {
    z-index: 2;
}
.flipclock-wrapper ul li a {
    display: block;
    height: 100%;
    -webkit-perspective: 200px;
    -moz-perspective: 200px;
    perspective: 200px;
    margin: 0 !important;
    overflow: visible !important;
    cursor: default !important;
}
.flipclock-wrapper ul li a div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    font-size: 80px;
    overflow: hidden;
    outline: 1px solid transparent;
}
.flipclock-wrapper ul li a div .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.flipclock-wrapper ul li a div.up {
    transform-origin: 50% 100%;
    top: 0;
}
.flipclock-wrapper ul li a div.up:after {
    content: "";
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 3px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
}
.flipclock-wrapper ul li a div.down {
    transform-origin: 50% 0;
    bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.flipclock-wrapper ul li a div div.inn {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 200%;
    color: #ccc;
    text-shadow: 0 1px 2px #000;
    text-align: center;
    background-color: #333;
    border-radius: 6px;
    font-size: 70px;
}
.flipclock-wrapper ul li a div.up div.inn {
    top: 0;
}
.flipclock-wrapper ul li a div.down div.inn {
    bottom: 0;
}


/* PLAY */

.flipclock-wrapper ul.play li.flipclock-before {
    z-index: 3;
}
.flipclock-wrapper .flip {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.flipclock-wrapper ul.play li.flipclock-active {
    animation: asd 0.5s 0.5s linear both;
    z-index: 5;
}
.flipclock-divider {
    float: left;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 100px;
}
.flipclock-divider:first-child {
    width: 0;
}
.flipclock-dot {
    display: block;
    background: #323434;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    left: 5px;
}
.flipclock-divider .flipclock-label {
    position: absolute;
    top: -1.5em;
    right: -86px;
    color: black;
    text-shadow: none;
}
.flipclock-divider.minutes .flipclock-label {
    right: -88px;
}
.flipclock-divider.seconds .flipclock-label {
    right: -91px;
}
.flipclock-dot.top {
    top: 30px;
}
.flipclock-dot.bottom {
    bottom: 30px;
}
@keyframes asd {
    0% {
        z-index: 2;
    }
    20% {
        z-index: 4;
    }
    100% {
        z-index: 4;
    }
}
.flipclock-wrapper ul.play li.flipclock-active .down {
    animation: turn 0.5s 0.5s linear both;
}
@keyframes turn {
    0% {
        transform: rotateX(90deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}
.flipclock-wrapper ul.play li.flipclock-before .up {
    z-index: 2;
    animation: turn2 0.5s linear both;
}
@keyframes turn2 {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(-90deg);
    }
}
.flipclock-wrapper ul li.flipclock-active {
    z-index: 3;
}
/* SHADOW */

.flipclock-wrapper ul.play li.flipclock-before .up .shadow {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
    background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
    animation: show 0.5s linear both;
}
.flipclock-wrapper ul.play li.flipclock-active .up .shadow {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
    background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
    animation: hide 0.5s 0.3s linear both;
}
/*DOWN*/

.flipclock-wrapper ul.play li.flipclock-before .down .shadow {
    background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
    background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
    animation: show 0.5s linear both;
}
.flipclock-wrapper ul.play li.flipclock-active .down .shadow {
    background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
    background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
    animation: hide 0.5s 0.2s linear both;
}
@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
